"use client"

import { useCallback, useEffect, useState } from "react"
import Image from "next/image"
import { ChevronLeft, ChevronRight } from "lucide-react"

interface SliderImage {
  src: string
  alt: string
  type: "image"
  heading: string
  description: string
}

interface SupervvisorHomeSliderProps {
  images: SliderImage[]
}

export function SupervvisorHomeSlider({ images }: SupervvisorHomeSliderProps) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  const goToSlide = useCallback((index: number) => {
    setCurrentSlide(index)
  }, [])

  const goToNextSlide = useCallback(() => {
    setCurrentSlide((current) => (current + 1) % images.length)
  }, [images.length])

  useEffect(() => {
    if (images.length <= 1 || isPaused) return

    const timer = setInterval(goToNextSlide, 7000) // Change slide every 5 seconds

    return () => clearInterval(timer)
  }, [goToNextSlide, images.length, isPaused])

  return (
    <div
      className="relative overflow-hidden"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* Slides */}
      <div
        className="flex w-full transition-transform duration-500 ease-out"
        style={{
          transform: `translateX(-${currentSlide * 33.333333333333336}%)`,
          width: `${images.length * 100}%`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="flex w-full flex-shrink-0 items-center"
            style={{ width: `${33.333333333333336}%` }}
          >
            <div className="slide-content flex flex-col">
              <div className="flex flex-row items-center gap-4 lg:flex-col lg:items-start">
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={48}
                  height={48}
                  className="rounded-lg object-contain"
                  priority
                />
                <h3 className="text-2xl leading-normal">{image.heading}</h3>
              </div>
              <p className="my-3 text-sm font-normal text-slate-800 lg:text-base">
                {image.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Dot Navigation */}
      {images.length > 1 && (
        <div className="mt-4 flex justify-center gap-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`h-2 w-2 rounded-full transition-all ${
                currentSlide === index
                  ? "w-4 bg-primary"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  )
}
