"use client"

import { useEffect, useState } from "react"
import Image from "next/image"
import Link from "next/link"
import { useSearchParams } from "next/navigation"
import { Loader2 } from "lucide-react"
import { toast } from "sonner"

import { mapStripeDataToProducts } from "@/lib/utils/product-mapper"
import { SupervvisorHomeSlider } from "@/components/supervvisor/supervvisor-home-slider"
import ProductCard from "@/components/supervvisor/supervvisor-product-card"

interface MarketingFeature {
  name: string
}

interface Price {
  id: string
  stripe_price_id: string
  currency: string
  unit_amount: number
  recurring: { interval: string }
  metadata?: {
    name: string
    description: string
  }
}

interface Product {
  id: string
  stripe_product_id: string
  name: string
  description: string
  images: string[]
  marketing_features?: MarketingFeature[]
  metadata: {
    category?: string
    agents_available?: string
    sites?: string
    current_agent_actions?: string
  }
  prices: Price[]
  isSubscribed: boolean
  capabilities?: { keyword: string; description: string }[]
  active: boolean
}

interface Category {
  id: number
  name: string
  description: string
  image: string
  active: boolean
  sort_order: number
}

const sliderImages = [
  {
    src: "/banner/banner2.png",
    alt: "Slide 1",
    type: "image" as const,
  },
]

interface HomeProps {
  session: {
    user?: {
      email?: string
      sub?: string
    } | null
  } | null
  supportLink?: string
}
export default function Home({ session, supportLink }: HomeProps) {
  const searchParams = useSearchParams()
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [productCount, setProductCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [subscribeProducts, setSubscribeProducts] = useState<string[]>([])
  const [cancelSubProducts, setCancelSubProducts] = useState<string[]>([])
  const [paymentPendingSubProducts, setPaymentPendingSubProducts] = useState<
    string[]
  >([])
  const [homeSliderImages, setHomeSliderImages] = useState<
    Array<{
      src: string
      alt: string
      type: "image"
      heading: string
      description: string
    }>
  >([])
  const [priceIdsMap, setPriceIdsMap] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const searchMetadata =
          "metadata['is_featured']:'true' AND active:'true'"
        const recordLimit = 6

        const response = await fetch(
          `/api/stripe/products?metadata=${searchMetadata}&limit=${recordLimit}`
        )
        const data = await response.json()
        const mappedProducts = mapStripeDataToProducts(
          data
        ) as unknown as Product[]
        let featuredProductsData = mappedProducts

        if (featuredProductsData.length < 6 && products.length > 0) {
          let displayFeaturedProducts = [...featuredProductsData]
          let productIndex = 0
          while (
            displayFeaturedProducts.length < 6 &&
            productIndex < products.length
          ) {
            const product = products[productIndex]
            if (
              product &&
              !displayFeaturedProducts.some((fp) => fp.id === product.id) &&
              product.active
            ) {
              displayFeaturedProducts.push(product)
            }
            productIndex++
          }
          setFeaturedProducts(displayFeaturedProducts)
        } else {
          setFeaturedProducts(featuredProductsData)
        }
        await fetchSubscriptions()

        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching featured products:", error)
        setFeaturedProducts([])
      }
    }

    if (products.length > 0) {
      fetchFeaturedProducts()
    }
  }, [products, session])

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    try {
      const recordLimit = 100

      const response = await fetch(`/api/stripe/products`)
      const data = await response.json()
      if (data.data) {
        setProductCount(data.data.length)
        const mappedProducts = mapStripeDataToProducts(
          data
        ) as unknown as Product[]
        setProducts(mappedProducts)
      } else {
        setProductCount(0)
      }
    } catch (error) {
      console.error("Error fetching products:", error)
      setProductCount(0)
    } finally {
    }
  }
  const fetchSubscriptions = async () => {
    try {
      if (session) {
        setIsLoading(true)
        const response = await fetch("/api/stripe/subscription")
        const subscriptions = await response.json()
        const productIdsSet = new Set()
        const cancelSubProductIdsSet = new Set()
        const paymentPendingSubProductIdsSet = new Set()

        // Build price map first
        const newPriceIdsMap = subscriptions.reduce(
          (acc: { [key: string]: string }, subscription: any) => {
            acc[subscription.stripe_product_id] = subscription.stripe_price_id
            return acc
          },
          {}
        )
        // Set price map once
        setPriceIdsMap(newPriceIdsMap)
        // Rest of the code...
        subscriptions.forEach((subscription: any) => {
          productIdsSet.add(subscription.stripe_product_id)
          if (subscription.cancel_at && subscription.cancel_at !== null) {
            cancelSubProductIdsSet.add(subscription.stripe_product_id)
          }
          if (
            subscription.subscription_status === "past_due" ||
            subscription.subscription_status === "incomplete"
          ) {
            paymentPendingSubProductIdsSet.add(subscription.stripe_product_id)
          }
        })
        const productIds = Array.from(productIdsSet) as string[] // Convert Set to Array and assert type
        setSubscribeProducts(productIds) // Set the filtered products
        setCancelSubProducts(Array.from(cancelSubProductIdsSet) as string[]) // Set the filtered products
        setPaymentPendingSubProducts(
          Array.from(paymentPendingSubProductIdsSet) as string[]
        )
      }
    } catch (error) {
      console.error("Error fetching products:", error)
      //setFeaturedProducts([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("/api/categories")
        const data = await response.json()

        if (data && Array.isArray(data)) {
          const sliderImages = data.map((category: Category) => ({
            src: category.image,
            alt: category.name,
            type: "image" as const,
            heading: category.name,
            description: category.description,
          }))
          setHomeSliderImages(sliderImages)
        }
      } catch (error) {
        console.error("Error fetching categories:", error)
      }
    }

    fetchCategories()
  }, [])

  useEffect(() => {
    const message = searchParams.get("message")
    if (message) {
      setTimeout(() => {
        toast.success(message)
      }, 500)
    }
  }, [searchParams])

  return (
    <div className="-mx-4 -mt-12 bg-[url('/banner/banner.svg')] bg-[length:100%] bg-top bg-no-repeat px-4">
      {/* Hero Section with Sliders */}
      <div className="mx-auto mb-4 flex max-w-[1130px] flex-col gap-4 pt-16 lg:mb-6 lg:flex-row lg:items-center lg:justify-between lg:gap-8">
        {/* Main Banner */}
        <div className="flex flex-1 flex-col items-center justify-center text-center lg:items-start lg:text-left">
          <h1 className="mb-4 text-2xl font-bold text-primary md:text-3xl lg:text-5xl">
            Workforce Empowerment with AI Powered Apps
          </h1>
          <p className="mx-auto max-w-2xl text-sm text-slate-800 lg:text-xl">
            Simplify complex tasks, streamline actions across different systems,
            and increase productivity with partner-built agents, actions, and AI
            apps.
          </p>
        </div>

        {/* Category Slider */}
        <div className="after:rounded-0 relative z-[1] mx-auto my-8 w-full after:absolute after:-bottom-5 after:-left-6 after:-top-5 after:z-[-1] after:h-[calc(100%+40px)] after:w-[calc(100%+36px)] after:bg-cyan-100 after:content-[''] lg:my-0 lg:max-w-[478px] lg:after:top-[3.9375rem] lg:after:h-[calc(100%-17px)] lg:after:w-full lg:after:rounded-xl">
          <div
            className="mx-auto rounded-xl bg-white p-4 shadow-lg lg:p-[30px]"
            id="category-slider"
          >
            <SupervvisorHomeSlider images={homeSliderImages} />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[1130px] text-center sm:text-left">
        <h2 className="text-2xl md:text-3xl">Innovations</h2>
        <p className="mb-6 text-slate-800">
          Explore our range of innovative solutions designed to meet your needs.
        </p>
      </div>
      {/* Featured products */}
      <div className="mx-auto grid w-full max-w-[1130px] gap-4 sm:gap-6 min-[821px]:grid-cols-2 xl:gap-10">
        {isLoading ? (
          <div className="col-span-full flex items-center justify-center p-3">
            <Loader2 className="h-8 w-8 animate-spin" />
            <span className="ml-2">Loading products...</span>
          </div>
        ) : featuredProducts.length > 0 ? (
          featuredProducts.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              productUrl={
                session
                  ? `/marketplace/${product.id}`
                  : `/products/${product.id}`
              }
              isSubscribed={subscribeProducts.includes(product.id)}
              isCancelled={
                cancelSubProducts.includes(product.id) ? true : false
              }
              isPaymentPending={
                paymentPendingSubProducts.includes(product.id) ? true : false
              }
              selectedPriceId={priceIdsMap[product.id]}
            />
          ))
        ) : (
          <div className="col-span-full text-center">
            <Image
              src="/working-on-it.svg"
              alt="No products available"
              width={200}
              height={200}
              className="mx-auto my-4 opacity-50"
            />
            <h2 className="text-3xl font-bold text-slate-900">
              We&apos;re working on it!
            </h2>
            <p className="mx-auto max-w-[504px] text-lg text-slate-600">
              We&apos;re bringing more products to the marketplace soon. Check
              back later, or{" "}
              <Link
                href={`mailto:${supportLink}`}
                className="text-slate-800 underline hover:text-slate-900"
              >
                contact us
              </Link>{" "}
              to request a product.
            </p>
          </div>
        )}
      </div>
      {/* View All Products Button */}
      {!isLoading && productCount && productCount > 6 && (
        <div className="my-4 flex justify-center">
          <button
            onClick={() => (window.location.href = "/products")}
            className="hover:bg-primary-600 rounded-lg bg-primary px-4 py-2 text-white"
          >
            View All
          </button>
        </div>
      )}

      {/* About Us Section */}
      {/* <section className="my-6 md:my-8 hidden">
        <div className="mx-auto max-w-4xl">
          <h2 className="mb-4 text-3xl font-bold text-primary dark:text-white">
            About Us
          </h2>
          <div className="prose max-w-none dark:prose-invert">
            <p className="mb-6 text-lg leading-relaxed text-gray-600 dark:text-gray-300">
              We are dedicated to providing innovative solutions that help
              organizations streamline their operations and achieve their
              goals. Our platform combines powerful tools with an intuitive
              interface to deliver exceptional value to our clients.
            </p>
            <div className="mt-8 grid gap-6 md:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900">
                  <svg
                    className="h-8 w-8 text-blue-600 dark:text-blue-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  Fast & Efficient
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Optimized performance for seamless operations
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-green-100 dark:bg-green-900">
                  <svg
                    className="h-8 w-8 text-green-600 dark:text-green-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  Secure & Reliable
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Enterprise-grade security for your peace of mind
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900">
                  <svg
                    className="h-8 w-8 text-purple-600 dark:text-purple-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">Customizable</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Tailored solutions to meet your unique needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* About us section */}
    </div>
  )
}
