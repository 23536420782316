"use client"

import { title } from "process"
import Link from "next/link"
import { useUser } from "@auth0/nextjs-auth0/client"

import { useHeader } from "./header-context"

export function Header() {
  const { items, activePath } = useHeader()
  const { user, isLoading } = useUser()

  // Filter navigation items based on auth status
  const navigationItems = items.filter((item) => {
    if (item.href === "/api/auth/login" && user) return false
    if (item.href === "/join" && user) return false
    if (item.href === "/api/auth/logout" && !user) return false
    if (item.href === "/dashboard" && !user) return false
    return true
  })

  return (
    <header className="flex items-center">
      <nav className="ml-auto flex items-center gap-4 lg:gap-6">
        {navigationItems.map((item) => (
          <Link
            key={item.href}
            href={item.href}
            className={`relative flex items-center space-x-1 text-sm sm:space-x-2 sm:text-base ${
              activePath === item.href
                ? "text-primary after:absolute after:-bottom-1 after:left-0 after:h-0.5 after:w-full after:bg-primary"
                : "text-muted-foreground hover:text-primary hover:after:absolute hover:after:-bottom-1 hover:after:left-0 hover:after:h-0.5 hover:after:w-full hover:after:bg-primary"
            }`}
          >
            {item.icon && <item.icon className="h-4 w-4" />}
            <span className="hidden sm:inline">{item.label}</span>
          </Link>
        ))}
      </nav>
    </header>
  )
}
